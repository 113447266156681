<template>
  <div class="col-12 col-lg-6 c g">
    <div class="card">
        <div class="card-footer" v-if="type != 'select' && !done">
            <div v-if="select_type == 'manual' || type != '1' && type != '4'">
                <div class="g">
                    اختر المعلمين <input type="text" placeholder="ابحث هنا..." v-model="q" @change="getStudents()" style="border: none; border: 1px solid #999; padding: 5px"> <button class="btn btn-sm"><i class="fa fa-search"></i> بحث</button> 
                </div>
                <div style="width: 100%; border: solid 2px #333; padding: 5px; overflow-y: scroll; height: 150px;">
                    <div class="form-check" v-for="student in students" :key="student._id">
                    <label class="form-check-label">
                        <input type="checkbox" class="form-check-input" v-model="selected_students" :value="student">
                        {{ student.name }}
                    </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer" v-if="type != 'select'">
            <div class="form-group" v-if="!done">
              <span for="">العنوان</span>
              <input type="text"
                class="form-control form-control-lg" v-model="title" placeholder="اكتب هنا..." @change="saveot()">
            </div>
            <div class="form-group" v-if="type == '3' && !done">
              <span for="">المحتوى</span>
              <textarea class="form-control" placeholder="اكتب هنا..." @change="saveot()" v-model="content_3" rows="3"></textarea>
            </div>
            <div class="row" v-if="!done">
                <div class="col-12 col-lg-6 g">
                    <div class="form-group">
                        <input id="one" style="width: 100%; border: 1px solid; height: 28px" class="form-control g" @change="saveot()" placeholder="اكتب هنا.." v-model="one">
                      <input type="text"
                        class="form-control" v-model="admin" placeholder="اكتب هنا..." @change="saveLocals()">
                    </div>
                </div>
                <div class="col-12 col-lg-6 g">
                    <div class="form-group">
                        <input id="one" style="width: 100%; border: 1px solid; height: 28px" class="form-control g" @change="saveot()" placeholder="اكتب هنا.." v-model="two">
                      <input type="text"
                        class="form-control" v-model="boss" @change="saveLocals()" placeholder="اكتب هنا...">
                    </div>
                </div>
            </div>
            <div class="col-12 text-center g" v-if="!done">
                <button class="btn btn-primary" v-if="!loading" @click="create()">
                    <i class="fa fa-check"></i>
                    انشاء الشهادات الآن
                </button>
                <img :src="require('@/assets/images/loading.svg')" v-if="loading" style="width: 60px" alt="">
                <span v-if="loading">جاري الانشاء... ربما تستغرق دقائق.</span>
            </div>
            <div class="col-12 text-center g" v-if="done && !Object.keys(links).length">
                <button class="btn btn-lg btn-primary" @click="sendx()" v-if="!loading_links">
                    <i class="fa fa-paper-plane"></i>
                    ارسال الشهادات للمعلمين
                </button>
                <button class="btn btn-lg btn-primary disabled" disabled id="sendp" v-if="loading_links">
                    جاري المعالجة...
                </button>
            </div>
            <div class="col-12 text-center g" v-if="done && Object.keys(links).length">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                        <label for="">نص الرسالة المرسلة</label>
                        <textarea class="form-control" v-model="message" @change="saveot()" rows="3"></textarea>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4 g">
                        <button class="btn btn-success btn-block"  @click="send('whatsapp')">
                            <i class="fa fa-whatsapp"></i>
                            واتس تلقائي
                        </button>
                    </div>
                    <div class="col-12 col-lg-4 g">
                        <button class="btn btn-success btn-block" @click="send('wa')">
                            <i class="fa fa-whatsapp"></i>
                            اداة المتصفح
                        </button>
                    </div>
                    <div class="col-12 col-lg-4 g">
                        <button class="btn btn-primary btn-block"  @click="send('sms')">
                            <i class="fa fa-mobile"></i>
                            SMS
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer" v-if="selected_students.length">
            <div class="col-12 table-responsive">
                <table class="table table-hover table-sm table-borderd">
                    <thead>
                        <th>
                            المعلم
                        </th>
                        <th>
                            الشهادة
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="x in selected_students" :key="x._id">
                            <td>
                                {{ x.name }}
                            </td>
                            <td>
                                <i class="text-muted" v-if="!linksx[x.number]">لم يتم الانشاء</i>
                                <div v-if="linksx[x.number]">
                                    <button @click='openlinkof(x.number, "blob", x.name)'  class='btn btn-primary btn-sm' style='border-radius: 0px'>
                                        <i class='fa fa-external-link'></i>
                                        عرض 
                                    </button>
                                    <button @click='openlinkof(x.number, "png", x.name)' class='btn btn-success btn-sm sendme ccccc' style='border-radius: 0px'>
                                        <i class='fa fa-download'></i>
                                        تحميل 
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div id="result" class="dnone"></div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            select_type: "manual",
            type: '3',
            custom_class: "",
            types: [
                "شهادة التميز السلوكي",
                "تهنئة تخرج",
                "شهادة شكر وتقدير عامة",
                "الالتزام بالحضور والانصراف"
            ],
            title: localStorage.getItem('ttitle') ?? "تتقدم إدارة المدرسة بالشكر والتقدير",
            user: JSON.parse(localStorage.getItem('user')),
            titles: [
                "",
                "تتقدم إدارة مدرسة {name} بالشكر والتقدير",
                "بكل عبارات المحبة والفرح تهنئ إدارة مدرسة {name}",
                "تتقدم إدارة مدرسة {name} بالشكر والتقدير",
            ],
            content_1: localStorage.getItem('tcontent_1') ?? `على تميزه السلوكي على مستوى طلاب المدرسة خلال الفصل الدراسي الثاني للعام 1446 هـ
كما نتقدم بالشكر الجزيل لأسرته على الاهتمام وحسن المتابعة
سائلين الله له التوفيق والنجاح`,
            content_2: localStorage.getItem('tcontent_2') ?? `لإتمامه سنوات الدراسة وتخرجه من المرحلة المتوسطة في هذا العام 1446 هـ
كما نتقدم بالشكر الجزيل لأسرته على الاهتمام وحسن المتابعة
سائلين الله له التوفيق والسداد`,
            content_3: localStorage.getItem('tcontent_3') ?? ``,
            content_4: localStorage.getItem('tcontent_4') ?? `على تميزه في الانضباط المدرسي والالتزام بالحضور خلال الفصل الدراسي الثاني للعام 1446 هـ
كما نتقدم بالشكر الجزيل لأسرته على الاهتمام وحسن المتابعة
سائلين الله له التوفيق والنجاح`,
            admin: "",
            start_date: "2024-11-17",
            boss: "",
            classes: [],
            students: [],
            selected_students: [],
            q: null,
            original: [],
            originald: false,
            linksx: {},
            one: localStorage.getItem("tone") ?? "الموجه الطلابي",
            two: localStorage.getItem("ttwo") ?? "مدير المدرسة",
            loading: false,
            done: false,
            loading_links: false,
            links: {},
            message: localStorage.getItem('tcerts_message') ?? `المعلم {name}

رابط الشهادة: {link}`,
            teachers: []
        }
    },
    created(){
        if(!checkPer("students|certs")){
            this.$router.push('/NotPermitted')
            return false;
        }
        this.boss = localStorage.getItem('boss');
        this.admin = localStorage.getItem('admin');
        this.getStudents()
    },
    methods: {
        custom_class_x(){
            var g = this;
            this.selected_students = this.students.filter(function(x){
                if(x.classname == g.custom_class){
                    return x;
                }else{
                    return false;
                }
            })
        },
        titleChanges(){
        },
        saveLocals(){
            localStorage.setItem('boss', this.boss);
            localStorage.setItem('admin', this.admin);
        },
        getStudents(){
            var g = this;
            $.post(api + '/user/teachers/list', {
                jwt: this.user.jwt,
                q: this.q,
                page: 1,
                perpage: 10000
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.students = r.response;
                }
            }).fail(function(){
                g.loading = false;
            })
        },
        saveot(){
            localStorage.setItem("tone", this.one);
            localStorage.setItem("ttitle", this.title);
            localStorage.setItem('ttwo', this.two)
            localStorage.setItem('tcerts_message', this.message)
            localStorage.setItem('tcontent_1', this.content_1)
            localStorage.setItem('tcontent_2', this.content_2)
            localStorage.setItem('tcontent_3', this.content_3)
            localStorage.setItem('tcontent_4', this.content_4)
        },
        create(){
            var g = this;
            if(!g.selected_students.length){
                return alert("برجاء اختيار المعلمين اولاً", 200);
            }
            g.loading = true
            g.done = false;
            if(!g.originald){
                g.originald = true;
                g.original = JSON.parse(JSON.stringify(g.selected_students));
            }
            var a = g.original[0];
                if(!a){
                    g.done = true;
                    g.linksx = JSON.parse(JSON.stringify(g.linksx))
                    return;
                }
                $("#result").html(`<canvas id="myCanvas"></canvas>
                <img style='display:none;'id='img'/>
                `)
                var canvas = document.getElementById('myCanvas');
                var ctx = canvas.getContext('2d');
                var imageObj = document.getElementById('img');
                imageObj.onload = function(e) {
                    ctx.canvas.width = imageObj.width;
                    ctx.canvas.height = imageObj.height;
                    ctx.drawImage(imageObj, 0, 0,imageObj.width,imageObj.height);
                    ctx.textBaseline = 'middle';
                    ctx.textAlign = "center";
                        ctx.font = "30pt Calibri";
                        ctx.fillText(g.title, 900, 400);
                        ctx.font = "50pt Calibri";
                        ctx.fillStyle = "green";
                        ctx.fillText(a.name, 900, 500);
                        ctx.fillStyle = "black";
                        var x = 600;
                        ctx.font = "30pt Calibri";
                        if(g.type == '1'){
                            g.content_1.split('\n').forEach(function(line){
                                ctx.fillText(line, 900, x);
                                x = x+60;
                            })
                        }
                        if(g.type == '2'){
                            g.content_2.split('\n').forEach(function(line){
                                ctx.fillText(line, 900, x);
                                x = x+60;
                            })
                        }
                        if(g.type == '3'){
                            g.content_3.split('\n').forEach(function(line){
                                ctx.fillText(line, 900, x);
                                x = x+60;
                            })
                        }
                        if(g.type == '4'){
                            g.content_4.split('\n').forEach(function(line){
                                ctx.fillText(line, 900, x);
                                x = x+60;
                            })
                        }
                        ctx.font = "30pt Calibri";
                        ctx.fillText(g.admin, 1250, 1000);
                        ctx.fillText(g.boss, 480, 1000);
                        ctx.fillStyle = "green";
                        ctx.fillText(g.one, 1250, 950);
                        ctx.fillText(g.two, 480, 950);
                        canvas.toBlob(function(blob){
                        g.linksx[a.number] = {
                            blob: URL.createObjectURL(blob),
                            png: canvas.toDataURL("png")
                        }
                        g.original.shift()
                        g.create()
                    });
                };
                imageObj.src = `/c-${g.type}.png?v=4`;
        },
        openlinkof(number, type, name){
            if(type=='blob'){
                window.open(this.linksx[number][type])
                return;
            }
            var link = document.createElement("a");
            link.download = name;
            link.href = this.linksx[number]['png'];
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        getAuto(){
            var g = this;
            $.post(api + '/user/students/bis/not-have-bis', {
                jwt: this.user.jwt
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.selected_students = r.response
                }
            }).fail(function(){
                g.loading = false;
            })
        },
        getAuto2(){
            var g = this;
            $.post(api + '/user/students/reports/between', {
                jwt: this.user.jwt,
                from: this.start_date,
                to: new Date().toISOString().split('T')[0],
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.selected_students = r.response.filter(function(x){
                        if(x.reports['3'].length == 0){
                            return x;
                        }else{
                            return false;
                        }
                    })
                }
            }).fail(function(){
                g.loading = false;
            })
        },
        sendx(){
            var g = this;
            g.loading_links = true;
            var arr = {}, before = JSON.parse(JSON.stringify(g.selected_students));
            function ll(){
            if(!before.length){
                g.loading_links = false;
                g.links = JSON.parse(JSON.stringify(arr));
            }else{
                var a = before[0];
                if(g.linksx[a.number]){
                    $.post("https://naqar.net/base64-to-image", {
                        data: g.linksx[a.number].png
                    }).then(function(r){
                        a.link = 'https://naqar.net/' + r;
                        arr[a.number] = a;
                        $("#sendp").html(`متبقي ${before.length} شهادة جاري معالجتها...`)
                        before.shift()
                        ll()
                    }).fail(function(){
                        ll()
                    })
                }else{
                    before.shift()
                    ll()
                }
            }
        }
        ll()
        },
        sendWAModal(arr){
            $("#sendmodalwaarray").val(JSON.stringify(arr))
            $("#sendmodalwatype").val("students")
            $("#sendWAModal").click()
        },
        send(method){
            var g = this;
            var messages = [];
            g.selected_students.forEach(function(a){
                var message = g.message;
                messages.push({
                    phones: [a.phone],
                    message: message.replace("{name}", a.name).replace("{link}", g.links[a.number].link)
                })
            })
            if(method == 'wa'){
                $("#sendmodalwaarray").val(JSON.stringify(messages))
                $("#sendmodalwatype").val("students")
                $("#sendWAModal").click()
            }else{
                if(!confirm(`متأكد من ارسال ${messages.length} رسالة؟`)){return false;}
                $.post(api + '/user/messages/send-arr', {
                    jwt: g.user.jwt,
                    arr: JSON.stringify(messages),
                    method: method,
                    type: "students"
                }).then(function(r){
                    if(r.status != 100){
                        alert(r.response, 200)
                    }else{
                        alert("تم الارسال بنجاح٫ يمكنك تصفح ارشيف الرسائل", 100)
                    }
                }).fail(function(){
                    alert("حدث خطأ", 200)
                })
            }
        }
    }
}
</script>

<style>

</style>